#main-content {
	padding: 40px 0;
}

.entry-content {
	h1, h2, h3, h4, h5, h6 {
		margin: 25px 0 10px;
		color: black;
		font-family: campton-medium, sans-serif;
		font-weight: 500;
	}

	p {
		color: #777;
	}
}
